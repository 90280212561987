const WelcomeCars = [
  {
    id: 1,
    image: "images/wc1.avif",
    name: "car1",
  },
  {
    id: 2,
    image: "images/wc2.avif",
    name: "car2",
  },
  {
    id: 3,
    image: "images/wc3.avif",
    name: "car3",
  },
];
export default WelcomeCars;
