const Cars = [
  {
    id: 1,
    image: "images/s-1.avif",
    name: "Mercedes S W223",
    category: "limousine",
    price: "2000pln",
    gallery: [
      "images/s-2.avif",
      "images/s-3.avif",
      "images/s-4.avif",
      "images/s-5.avif",
    ],
    year: "2023",
    engine: "3.0 330hp",
  },
  {
    id: 2,
    image: "images/p-1.avif",
    name: "Porsche Macan",
    category: "sport",
    price: "1600pln",
    gallery: ["images/p-2.avif", "images/p-3.avif", "images/p-4.avif"],
    year: "2022",
    engine: "2.0 V6 258hp",
  },
  {
    id: 3,
    image: "images/a6-1.avif",
    name: "Audi A6 C8 Avant",
    category: "limousine",
    price: "1500pln",
    gallery: ["images/a6-2.avif", "images/a6-3.avif", "images/a6-4.avif"],
    year: "2023",
    engine: "3.0 50 TDI 286hp",
  },
  {
    id: 4,
    image: "images/aq8-1.avif",
    name: "Audi Q8 S-line",
    price: "1800pln",
    gallery: [
      "images/aq8-2.avif",
      "images/aq8-3.avif",
      "images/aq8-4.avif",
      "images/aq8-5.avif",
    ],
    year: "2020",
    engine: "3.0 50 TDI 286hp",
  },
  {
    id: 5,
    image: "images/rs6-1.avif",
    name: "Audi RS6 C8 Avant",
    price: "2000pln",
    gallery: [
      "images/rs6-2.avif",
      "images/rs6-3.avif",
      "images/rs6-4.avif",
      "images/rs6-5.avif",
    ],
    year: "2022",
    engine: "4.0 TFSI 600hp",
  },
  {
    id: 6,
    image: "images/bmw-x5-1.avif",
    name: "BMW X5",
    price: "1600pln",
    gallery: [
      "images/bmw-x5-2.avif",
      "images/bmw-x5-3.avif",
      "images/bmw-x5-4.avif",
      "images/bmw-x5-5.avif",
    ],
    year: "2020",
    engine: "",
  },
];

export default Cars;
